<template>
  <div class="container">
    <div class="banner">
      <div class="local-title">
        <div class="local-name HeiJian"
             v-if="VenueInfo.venueName"
             :class="{font24:VenueInfo.venueName.length >6}">{{VenueInfo.venueName}}</div>
        <div class="follow-bar"
             v-if="!isNaN(Number(VenueInfo.followCount) + Number(VenueInfo.realFollowCount))">{{Number(VenueInfo.followCount) + Number(VenueInfo.realFollowCount)}}人关注</div>
      </div>
    </div>
    <AppTabbar v-model:curIndex="curIndex"
               :tabList="tabList" />
    <div class="cultural-list"
         v-show="curIndex === 0">
      <!-- 商品列表 -->
      <ul class="goods-list"
          v-if="resultList.length">
        <li v-for="item in resultList"
            :key="item.goodsId"
            @click="handleToGoods(item)">
          <!-- <RouterLink to="/"> -->
          <img v-lazy="item.pictureList">
          <p class="name ellipsis-2">{{ item.title }}</p>
          <p class="price-bar"><span class="discount">&yen; {{item.price}}</span> <span class="org-price">&yen; {{item.yptMarketPrice}}</span> </p>
          <!-- </RouterLink> -->
        </li>
      </ul>
      <qdzEmpty v-else
                state="notData" />

      <qdzPagination v-if="resultList.length && refresh"
                     :total="totalCount"
                     :pageSize="pageSize"
                     :currentPage="pageNo"
                     @handleCheckPage="handleCheckPage" />
    </div>

    <!-- 富文本内容 -->
    <div class="rich-content"
         v-show="curIndex === 1 || curIndex === 2">
      <div class="rich-text"
           v-show="curIndex === 1"
           v-html="culturalContent">

      </div>
      <div class="rich-text"
           v-show="curIndex === 2"
           v-html="travelContent">
      </div>
      <qdzEmpty v-if="curIndex === 1 && !culturalContent"
                state="notData" />
      <qdzEmpty v-if="curIndex === 2 && !travelContent"
                state="notData" />
    </div>

    <!-- 地方馆 -->
    <div class="local-venue-list"
         v-if="curIndex === 3">
      <LocalVenue :id="VenueInfo.provinceId"
                  :areaType="1" />
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AppTabbar from '@/components/app-tabbar.vue'
import LocalVenue from '../components/local-venue.vue'
import { getVenueById, getGoodsListByVenueId, getContentByVenueId, } from '@/api/localPavilion'

export default {
  name: 'CityPavilion',
  components: {
    AppTabbar,
    LocalVenue
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const curIndex = ref(0)
    const state = reactive({
      keyword: '',
      pageNo: 1,
      totalCount: 1,
      totalPage: 1,
      pageSize: 10,
      resultList: [],
      refresh: true,
      rankType: 0,
      countyName: '',
      culturalContent: '',
      travelContent: '',
      VenueInfo: {},
      list: [],
      currentPage: 1,
      tabList: ['场馆首页', '文化风俗', '生态旅游', '地方馆'],
      areaType: 0
    })
    const getVenueInfo = (data) => {
      getVenueById(data).then(res => {
        // console.log(res)
        state.VenueInfo = res.data
      })
    }
    const handleGetResultList = (data) => {
      getGoodsListByVenueId({
        type: state.areaType,
        ...data
      }).then(res => {

        if (res.code === '10000') {
          state.resultList = res.data.list
          state.pageNo = res.data.pageNo
          state.totalCount = res.data.totalCount
          state.refresh = true
        }
      })
    }
    // 切换分页
    const handleCheckPage = (pageNo) => {
      state.pageNo = pageNo
      handleGetResultList({
        venueId: route.query.id,
        pageNo: pageNo,
      })
    }
    // 去详情页
    const handleToGoods = (item) => {
      // 条件判断跳转 文章详情 还是、商品详情
      // console.log(item)

      let toPath = router.resolve('/goods?goodsId=' + item.goodsId)
      window.open(toPath.href, '_blank')


    }
    // 1-获取风俗 、 2-旅游信息
    const getContent = () => {

      if (state.culturalContent && state.travelContent) return
      getContentByVenueId(
        {
          contentType: curIndex.value,
          venueId: route.query.id
        }
      ).then(res => {
        // console.log(res)
        if (curIndex.value === 1) state.culturalContent = res.data.content
        if (curIndex.value === 2) state.travelContent = res.data.content
      })
    }
    watch(() => route.query, (value, oldVal) => {
      // 关键词不同时 重新 初始化分页器 优化
      if (value.id != oldVal?.id) {
        state.refresh = false
      }
      if (value.areaType === '0') {
        state.tabList = ['场馆首页', '文化风俗', '生态旅游', '地方馆']

      }
      if (value.areaType === '1') {
        state.tabList = ['场馆首页', '文化风俗', '生态旅游']
      }
      //重置文化、旅游
      state.culturalContent = ''
      state.travelContent = ''
      state.areaType = value.areaType || 0
      curIndex.value = 0
      handleGetResultList({
        venueId: value.id
      })
      getVenueInfo({
        id: value.id,
      })

    }, { immediate: true })

    watch(() => curIndex.value, (nval, oldVal) => {
      if (nval != oldVal) {
        state.refresh = false
        state.pageNo = 1
      }

      if (curIndex.value === 0) handleGetResultList({
        venueId: route.query.id,
        pageNo: state.pageNo
      })
      if (curIndex.value === 1 || curIndex.value === 2) getContent()
      // if (curIndex.value === 3) 
    }, { immediate: true })
    return {
      curIndex,
      ...toRefs(state),
      handleCheckPage,
      handleToGoods
    }
  }

}
</script>
<style scoped lang="less">
.banner {
  height: 485px;
  // background: #efe9dc;
  background-image: url(~@/assets/images/local/city-banner.png);
  background-size: 100%;
  margin-bottom: 29px;
  position: relative;
  .local-title {
    width: 310px;
    height: 132px;
    padding-top: 22px;
    position: absolute;
    top: 50%;
    left: 37%;
    .local-name {
      font-size: 39px;
      font-weight: 400;
      color: #69381a;
      text-align: center;
    }
    .follow-bar {
      font-size: 12px;
      font-weight: 400;
      color: #69381a;
      text-align: center;
    }
  }
}
.cultural-list {
  min-height: 690px;
  .goods-list {
    display: flex;
    flex-wrap: wrap;
    height: 686px;
    li {
      width: 240px;
      height: 333px;
      background: #fff;
      margin-bottom: 10px;
      margin-right: 10px;
      // transition: none !important;
      cursor: pointer;
      &:nth-child(5n) {
        margin-right: 0;
      }
      .hoverShadow();
      img {
        width: 240px;
        height: 217px;

        padding: 25px 19px;
      }
      .name {
        width: 209px;
        height: 40px;
        line-height: 20px;
        overflow: hidden;
        margin: 14px auto;
        font-size: 14px;
        font-family: PingFang TC-Regular, PingFang TC;
        font-weight: 400;
        color: #5f6674;
      }

      .price-bar {
        padding: 0 14px;
        color: @priceColor;
        .org-price {
          font-size: 12px;
          font-weight: 400;
          color: #dfe0e3;
          margin-left: 10px;
        }
      }
    }
  }
}
.rich-content {
  width: 1240px;
  min-height: 690px;
  background: #ffffff;
  margin: 0 auto 10px;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  text-align: center;
  .rich-text {
    width: fit-content;
    margin: 0 auto;
  }
}
.font24 {
  font-size: 24px !important;
  margin: 10px 0;
}
</style>