<template>
  <template v-if="list.length">
    <template v-for="(item,index) in list"
              :key="index">
      <div class="local-venue-container">
        <div class="local-venue-top">
          <div class="local-venue-heard">
            <div class="local-heard-title">
              <div class="name ellipsis HeiJian"
                   :title="item.venueName"
                   :class="{font18:item.venueName.length >6}">{{item.venueName}}</div>

              <div class="follow">{{Number(item.followCount) + Number(item.favoriteCount)}}人关注</div>
            </div>
            <div class="local-heard-entry"
                 @click="goDetail(item)">立即进入</div>
          </div>
        </div>
        <div class="local-good-list">
          <Transition name="fade">
            <ul class="goods-list">
              <li v-for="sitem in item.goodsList"
                  :key="sitem.id"
                  @click="handleToGoods(sitem)">
                <img v-lazy="sitem.pictureUrl"
                     :alt="sitem.goodsName">
                <p class="name ellipsis-2">{{ sitem.goodsName }}</p>
                <p class="price-bar"><span class="discount">&yen; {{sitem.price}}</span> <span class="org-price">&yen; {{sitem.yptMarketPrice}}</span> </p>
              </li>
            </ul>
          </Transition>
        </div>
      </div>
    </template>
  </template>
  <qdzEmpty v-else
            :state="'notData'" />
  <div ref="target"
       class="more"
       v-show="totalCount != list.length && list.length != 0">
    努力加载中...
  </div>

  <!-- <qdzPagination v-if="list.length && refresh "
                 :total="totalCount"
                 :pageSize="pageSize"
                 :currentPage="pageNo"
                 @handleCheckPage="handleCheckPage" /> -->
</template>

<script>
import { useIntersectionObserver } from "@vueuse/core";

import { defineComponent, reactive, toRefs, onMounted, warn, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getVenueList } from '@/api/localPavilion'
import qdzEmpty from '@/components/library/qdz-empty.vue'

export default defineComponent({
  components: { qdzEmpty },
  props: {
    id: {
      type: [String, Number],
      default: () => ''
    },
    areaType: {
      type: Number,
      default: () => 0
    }
  },
  setup (props) {
    const router = useRouter()
    const state = reactive({
      pageNo: 1,
      list: [],
      totalCount: 1,
      totalPage: 1,
      pageSize: 10,
      currentPage: 1,
      refresh: true,
    })
    onMounted(() => {
      getList()
    })
    const getList = () => {
      getVenueList(
        {
          pageNo: state.pageNo,
          pageSize: 4,
          count: 5,
          provinceId: props.id,
          type: props.areaType
        }
      ).then(res => {
        if (res.code === '10000') {
          state.list = [...state.list, ...res.data.list]
          state.currentPage = res.data.currentPage
          state.totalCount = res.data.totalCount
          state.refresh = true
        }
      })
    }
    const handleToGoods = (item) => {
      // 条件判断跳转 文章详情 还是、商品详情
      // console.log(item)

      let toPath = router.resolve('/goods?goodsId=' + item.id)
      window.open(toPath.href, '_blank')

    }
    // 切换分页
    const handleCheckPage = () => {
      // console.warn(333333)
      state.pageNo = state.pageNo + 1
      getList()
    }
    // 场馆详情
    const goDetail = (item) => {
      if (item.isSale != 0) {
        // this.$uniAPI.toast('当前场馆已下架');
        alert('当前场馆已下架')
        return
      };
      // console.log(item)
      router.push(`/localPavilion/cityPavilion?id=${item.id}&areaType=${props.areaType}`)
    }


    // 模拟滚动加载
    const target = ref(null)
    const targetIsVisible = ref(false)
    const { stop } = useIntersectionObserver(
      target,
      ([{ isIntersecting }], observerElement) => {
        targetIsVisible.value = isIntersecting
        if (isIntersecting) handleCheckPage()
      },
    )

    return {
      ...toRefs(state),
      goDetail,
      handleCheckPage,
      handleToGoods,
      target,
      targetIsVisible
    }
  },
})
</script>
<style scoped lang="less">
.local-venue-container {
  width: 100%;
  height: 500px;
  margin-bottom: 50px;
  .local-venue-top {
    background-image: url(~@/assets/images/local/title.png);
    background-size: 100%;
    height: 264px;
    .local-venue-heard {
      padding: 20px 24px;

      .local-heard-title {
        width: 241px;
        height: 112px;
        margin: 0 auto;
        padding-top: 33px;
        .name {
          width: fit-content;
          margin: 0 auto;
          font-size: 29px;
          font-weight: 400;
          letter-spacing: 3px;
          color: #69381a;
          line-height: 30px;
          margin-bottom: 7px;
        }
        .follow {
          width: fit-content;
          margin: 0 auto;
          font-size: 12px;
          font-weight: 400;
          color: #69381a;
          line-height: 11px;
        }
      }
      .local-heard-entry {
        width: 93px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-left: auto;
        background: rgba(255, 255, 255, 0.08);
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        border: 1px solid #ffffff;
        cursor: pointer;
      }
    }
  }
  .local-good-list {
    margin-top: -80px;
  }
}
.goods-list {
  display: flex;
  flex-wrap: wrap;
  height: 686px;
  li {
    width: 240px;
    height: 333px;
    background: #fff;
    margin-bottom: 10px;
    margin-right: 10px;
    // transition: none !important;
    cursor: pointer;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .hoverShadow();
    img {
      width: 240px;
      height: 217px;

      padding: 25px 19px;
    }
    .name {
      width: 209px;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      margin: 14px auto;
      font-size: 14px;
      font-family: PingFang TC-Regular, PingFang TC;
      font-weight: 400;
      color: #5f6674;
    }

    .price-bar {
      padding: 0 14px;
      color: @priceColor;
      .org-price {
        font-size: 12px;
        font-weight: 400;
        color: #dfe0e3;
        margin-left: 10px;
      }
    }
  }
}
.more {
  width: 140px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #e3d8a2;
  color: #69381a;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.font18 {
  font-size: 18px !important;
}
</style>
